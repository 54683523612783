import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import '../styles/3_pages/404.scss';

const NotFound404 = ({ location }) => {
  const [params, setParams] = useState('');

  useEffect(() => {
    setParams(location.search);
  }, []);

  return (
    <Layout location={location}>
      <SEO title={process.env.SITE_TITLE} />
      <div className="not-found">
        <h1>404</h1>
        <h1>Page not found</h1>
        <a href={`${process.env.LANDING_PAGE_URL}/${params}`}>
          Continue to site
        </a>
      </div>
    </Layout>
  );
};

NotFound404.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default NotFound404;
